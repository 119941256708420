import common from '@mixins/common';
export default {
  name: 'AssetsInSuccess',
  mixins: [common],
  data() {
    return {
      myTitle: '',
      tip: ''
    };
  },
  created() {
    let sourceType = this.$route.query.sourceType;
    if (sourceType == 1) {
      this.myTitle = '采购入库';
      this.tip = '采购入库成功';
    } else if (sourceType == 2) {
      this.myTitle = '调拨入库';
      this.tip = '调拨入库成功';
    } else {
      this.myTitle = '入库';
      this.tip = '入库成功';
    }
  },
  methods: {}
};